:root {
    --sidebar-bg: #f4f4f4;
    --nav-bg: #ffffff;
    --chat-bg: #f9f9f9;
    --text-color: #000;
  }
  
  .dark-mode {
    --sidebar-bg: #333;
    --nav-bg: #222;
    --chat-bg: #111;
    --text-color: #fff;
  }
  
  .app-container {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  