.login_left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #9a41cb;

    .centerdiv {
        background: white;
        padding: 2rem;
        text-align: center;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .custom_inputContainer_select {
        position: relative;
        width: 350px;
        margin-top: 1.5rem;


    }

    .ForgetPassword {
        font-size: 13px;
        font-weight: 550;
        margin-bottom: 7px;
        font-family: "Orbitron" !important;
        color: #9a41cb;
        text-align: right;
        cursor: pointer;
    }

    .css-1o5fdv0-ReactDropdownSelect:focus,
    .css-1o5fdv0-ReactDropdownSelect:focus-within {
        outline: 0px;
        box-shadow: rgba(0, 116, 217, 0.2) 0px 0px 0px 0px !important;
    }


    .css-1o5fdv0-ReactDropdownSelect:hover,
    .css-1o5fdv0-ReactDropdownSelect:focus-within {
        border-color: #e5e5e5;
    }

    .css-1nfsgj8-ItemComponent.react-dropdown-select-item-selected {
        background: #9a41cb;
    }

    .css-1nfsgj8-ItemComponent:hover,
    .css-1nfsgj8-ItemComponent:focus {
        background: transparent;
    }

    .css-1o5fdv0-ReactDropdownSelect {

        background: #fff;
        border: 0.5px solid #e5e5e5;
        border-radius: 6px;
        outline: none;
        padding: 10px;
        width: 100%;
        font-family: "Exo 2" !important;

        input {
            background: #fff;


            outline: none;

            width: 100%;
            font-family: "Exo 2" !important;

            &::placeholder {
                color: #9a41cb;
            }

        }
    }

    .custom_inputContainer {
        position: relative;
        width: 350px;
        margin-top: 1.5rem;

        input {
            background: #fff;
            border: .5px solid #e5e5e5;
            border-radius: 6px;
            outline: none;
            padding: 10px;
            width: 100%;
            font-family: "Exo 2" !important;

            &::placeholder {
                color: #9a41cb;
            }

        }
    }

    .wellcomeback {
        font-size: 27px;
        font-weight: 550;
        margin-bottom: 7px;
        font-family: "Orbitron" !important;
        color: #9a41cb;
        text-align: center;
    }

    .smalltext {
        font-size: 11px;
        font-weight: 550;
        margin-bottom: 7px;
        font-family: "Orbitron" !important;
        color: #9a41cb;
        text-align: center;

    }

    .svgclass {
        height: 81px;
        margin-bottom: 1.5rem;
    }

    .logoinbuton {
        background: #9a41cb;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        padding: 14px;
        text-align: center;
        transition: all 0.3s linear;
        width: 350px;
        font-family: "Orbitron" !important;
        font-size: 17px;
        margin-top: 1.5rem;

        &:hover {
            transform: scale(1.03);
            transition: all 0.3s linear;
        }
    }

    .custominpu {
        height: max-content;
        position: absolute;
        right: .5rem;
        top: 46px;
        transform: translate(-50%, -50%);

        svg {
            color: #9a41cb;
            cursor: pointer;
        }
    }
}

.accointsingin {
    font-size: 14px;
    font-weight: 550;
    margin-bottom: 7px;
    font-family: "Orbitron" !important;
    color: #9a41cb;
    text-align: center;
    margin-top: 11px;

    span {
        font-weight: 700;
        cursor: pointer;
        transition: all 0.3s linear;

        &:hover {
            transform: scale(1.03);
            transition: all 0.3s linear;
        }
    }
}

.loderlogin {
    background: #9a41cb;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transition: all 0.3s linear;
        animation: blink 1s infinite;
    }
}

@keyframes blink {
    0% {
        opacity: 0
    }

    49% {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}

.dark-mode-login {
    background-color: #000 !important;

    .logoinbuton {
        background-color: #000 !important;
        color: #fff;
    }

    svg {
        color: #000 !important;
    }

    input {
        &::placeholder {
            color: #000 !important;
        }
    }

    .wellcomeback {
        color: #000 !important;
    }

    .smalltext {
        color: #000 !important;
    }
}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .login_left {
        height: auto;
        background: white;
    }

    .login_left .centerdiv {
        background: white;
        padding: 72px 5%;
        width: 100%;
        box-shadow: none;
    }

    .login_left .custom_inputContainer {
        position: relative;
        width: 100%;
        margin-top: 1.5rem;
    }

    .login_left .logoinbuton {
        background: #9a41cb;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        padding: 14px;
        text-align: center;
        transition: all 0.3s linear;
        width: 100%;
        font-family: "Orbitron" !important;
        font-size: 17px;
        margin-top: 1.5rem;
    }
}