.navbarItemdata {
    background: white;
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    padding: 0 5%;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightsidedata {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    .rightside {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        background: #9A41CB;
        width: 165px;
        height: 45px;

        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .leftside {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        background: #000000;

        width: 165px;
        height: 45px;

        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {

    .navbarItemdata {
        .rightsidedata {

            display: none;
        }

    }

}