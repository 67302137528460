.lucaleftside-section {
    background: rgb(255 255 255);
    height: 100vh;
    max-width: 250px;
    min-width: 250px;
    padding: 1rem;

    border-right: 1px solid #ddd;

    .topsection {
        display: flex;
        align-items: start;
        justify-content: space-between;
        position: relative;
    }

    .newchat {
        font-family: "Orbitron" !important;
        font-weight: 500;
        line-height: 16px;
        font-size: 14px;
        margin-bottom: 0;
        text-align: left;
        color: white;
        width: 100%;
        background: white;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        border: 0px solid #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background: #9a41cb;
        transition: all 0.5s ease-out;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 1rem;
    }

    .searchrelative {
        margin-top: 1.2rem;
        position: relative;
    }

    .fasearch {
        color: #ddd;
        position: absolute;
        top: 10px;
        left: 8px;
    }

    .svglogo {
        height: 52px;
        transition: all 0.5s ease-out;
        cursor: pointer;

        &:hover {
            transition: all 0.5s ease-out;
            transform: scale(1.03);
        }
    }

    .edit {

        transition: all 0.5s ease-out;
        cursor: pointer;
        position: absolute;
        // z-index: 9;
        right: 0;
        top: -10px;
        transition: all 0.5s ease-out;

        &:hover {
            transition: all 0.5s ease-out;
            transform: scale(1.03);
        }
    }

    .listsectionsvg {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
    }

    .Recorddiv {
        height: 100%;
        background: #dddddd69;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: 1rem;
        padding-bottom: 1rem;

        div {
            font-size: 18px;
            line-height: 28px;
            font-family: "Orbitron" !important;
            display: flex;
            align-items: center;
            font-weight: 600;
            grid-gap: 10px;
            gap: 10px;
            color: #9a41cb;
        }
    }

    .listsvg {
        cursor: pointer;
        transition: all 0.5s ease-out;

        &:hover {
            transition: all 0.5s ease-out;
            transform: scale(1.03);
        }
    }

    .sendeidt {
        cursor: pointer;
        transition: all 0.5s ease-out;

        &:hover {
            transition: all 0.5s ease-out;
            transform: scale(1.03);
        }
    }

    .addeditinput {
        display: flex;
        align-items: center;
        gap: 11px;
    }

    .box-section {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-top: 10px;
        flex-direction: column-reverse;
        justify-content: start;
    }

    .box {
        position: relative;

        width: 100%;
        background: white;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        border: 0px solid #000;
        color: #9a41cb;
        background: #dddddd78;
        transition: all 0.5s ease-out;

        .box-text {
            font-family: "Orbitron" !important;
            font-weight: 500;
            line-height: 16px;
            font-size: 13px;
            margin-bottom: 0;
            text-align: left;
            color: #9a41cb;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: all 0.5s ease-out;
            width: 92%;
        }

        &:hover {
            .box-text {
                transform: scale(1.03);
                transition: all 0.5s ease-out;
            }
        }
    }

    .leftsidediv {
        position: relative;
        height: calc(100% - 57px);
    }

    .insideboxhsitory {
        height: calc(100vh - 439px);
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .insidebox {
        height: 167px;
        overflow: scroll;
        padding-bottom: 0.5rem;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .conversationstart {
        margin-top: 1rem;
    }

    input {
        border: none;
        /* border: navajowhite; */
        height: 31px;
        width: 100%;
        border-radius: 5px;
        outline: none;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        height: 36px;
        background: #fff;
        border-radius: 10px;
        padding: 6px 6px 9px 32px;
        font-family: "Exo 2" !important;
        font-size: 14px;
    }

    .textconverstion {
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
    }

    .conversationstart {}

    .expand {
        cursor: pointer;
        transition: all 0.5s ease-out;
        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease-out;
        }
    }
}


.dark-mode-nav {
    background-color: #000 !important;
    border-right: 0px solid #ddd !important;


    svg {
        color: #fff !important;
    }


    .box-text {
        color: #fff !important;
    }

    .Recorddiv {
        background: #fffdfd45 !important;
    }

    .textconverstion {
        color: #fff !important;
    }

    .Recorddiv div {
        color: #fff !important;
    }

    .box {

        color: #fff !important;
        background: #fffdfd45 !important;
        transition: all 0.5s ease-out;
    }

    .newchat {
        background-color: #fff !important;
        color: #000 !important;

        svg {
            color: #000 !important;
        }

    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {


    .lucaleftside-section {
        background: rgb(255, 255, 255);
        height: 100vh;
        max-width: 100&;
        min-width: 100%;
        padding: 1rem;
        position: relative;
        border-right: 0px solid #ddd;
        position: absolute;
        z-index: 9;
        width: 100%;
        left: -100rem;
        // display: none
    }

}