/* TopNav.css */
.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--nav-bg);
    color: var(--text-color);
    padding: 15px 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--switch-bg);
    transition: 0.4s;
    border-radius: 20px;
}

input:checked+.slider {
    background-color: var(--switch-active);
}