.topNavbar {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 5%;
    height: 70px;



    .login {
        height: 34px;
        background: #9a41cb;
        color: white;
        width: 91px;
        font-size: 14px;
        font-family: "Orbitron" !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .heymode {
        color: #9a41cb;
    }

    .showrightsied {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .mode {
        color: #9a41cb;
    }

    .logoutlist {
        position: absolute;
        height: 93px;
        width: 100px;
        right: 0;
        top: 49px;
        background: red;
        border-radius: 10px;
        padding: 8px 8px;
        text-align: center;
        ont-size: 23px;
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 33px;
        margin-bottom: 2rem;
        text-align: center;
        background: #9a41cb;
        color: white;
        z-index: 9;

        div {
            cursor: pointer;
        }
    }

    .imageSection {
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #9a41cb;
        font-weight: 600;
        font-family: "Orbitron" !important;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background: #dddddd42;
        position: relative;
    }

    .text {
        font-size: 30px;
        line-height: 37px;
        font-family: "Orbitron" !important;
        display: flex;
        align-items: center;
        font-weight: 600;
        gap: 10px;
        color: #9a41cb;

    }
}

.rightsidedaata {
    width: 100%;

    p {
        font-size: 14px;
        margin-top: 0.5rem;
    }

    li {
        font-size: 14px;
        margin-top: 10px;
    }

    strong {
        font-size: 16px;
    }

    h1 {
        font-size: 27px;
        font-weight: 600;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
        text-align: left;
    }
    
    table th {
        border: 1px solid #ddd;
        padding: 8px;
        background-color: #f2f2f2;
        font-weight: bold;
    }
    
    table td {
        border: 1px solid #ddd;
        padding: 8px;
    }
    dl,
    ol,
    ul {
        padding-left: 15px;
    }
    h5 {
        font-size: 16px !important;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-weight: 400 !important;
    }
    h4 {
        font-size: 19px !important;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-weight: 400 !important;
    }

    h3 {
        font-weight: 700;
        font-size: 19px;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
    }

    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }

    .bottomchatsection {
        height: calc(100vh - 70px);
        background: #dddddd42;
        width: 100%;
        padding: 0 5%;
        position: relative;
    }

    .bottommaragefile {
        background: white;
        width: 100%;
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 10px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        height: 34px;
    }

    .textareadiv {
        background: white;
        width: 90%;
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 10px;
    }

    .fileNamediv {

        font-size: 16px;
        font-weight: 700;
        line-height: 2.25rem;
        text-decoration: underline;
        text-align: center;
        color: #9A41CB;
        font-family: "Orbitron" !important;
        position: absolute;
        white-space: nowrap;
        display: flex;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0px;
        justify-content: center;

    }

    .mobilebuon {
        width: 13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: #9A41CB !important;
        font-size: 15px;
        margin: auto auto 1rem !important;
        font-size: 14px !important;
        font-family: "Orbitron" !important;
        border: 0px !important;
        background: transparent !important;
        cursor: pointer;

        svg {
            color: #9A41CB !important;
            fill: #9A41CB !important;
        }
    }

    button:focus {
        outline: 0px dotted !important;
        outline: 0px auto -webkit-focus-ring-color !important;
    }

    .responddiv {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 7rem;
    }

    .fileNamediv1 {
        color: #9A41CB;
        font-size: 16px;
        font-weight: 700;
        line-height: 2.25rem;
        text-decoration: underline;
        text-align: center;
        color: #9A41CB;
        font-family: "Orbitron" !important;
        position: absolute;
        white-space: nowrap;
        display: flex;
        left: 0;
        right: 0;
        top: 0;
        width: 60%;
        margin: auto;


        justify-content: center;

    }

    .loading__letter {
        font-size: 16px;
        font-weight: 700;
        line-height: 2.25rem;
        text-decoration: underline;
        text-align: center;
        color: #9A41CB;
        font-family: "Orbitron" !important;

        animation-name: bounce;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    .loading__letter:nth-child(2) {
        animation-delay: .1s;
    }

    .loading__letter:nth-child(3) {
        animation-delay: .2s;
    }

    .loading__letter:nth-child(4) {
        animation-delay: .3s;
    }

    .loading__letter:nth-child(5) {
        animation-delay: .4s;
    }

    .loading__letter:nth-child(6) {
        animation-delay: .5s;
    }

    .loading__letter:nth-child(7) {
        animation-delay: .6s;
    }

    .loading__letter:nth-child(8) {
        animation-delay: .8s;
    }

    .loading__letter:nth-child(9) {
        animation-delay: 1s;
    }

    .loading__letter:nth-child(10) {
        animation-delay: 1.2s;
    }

    @keyframes bounce {
        0% {
            transform: translateY(0px)
        }

        40% {
            transform: translateY(-10px);
        }

        80%,
        100% {
            transform: translateY(0px);
        }
    }


    .mangeicon {
        cursor: pointer;
        color: #9a41cb;

        &:hover {
            transform: scale(1.06);
        }
    }

    .showtextinput {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    textarea {
        width: 100%;
        height: 6rem;
        padding: 1rem;
        border: 0px;
        border-radius: 10px;
        padding-bottom: 32px;
        font-family: "Exo 2" !important;
    }
}


.toplucanavbar {
    height: 100vh;
    width: 100%;

    h2 {
        font-size: 22px;
        margin-bottom: 0rem;
        margin-top: 0.5rem;
        font-weight: 600;
        line-height: 22px;
    }

    .flextshow {
        display: flex;
        align-items: self-start;
        grid-gap: 1rem;
        gap: 1rem;
        width: 48%;
    }

    .heltext {
        font-size: 35px;
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 2.25rem;
        margin-bottom: 2rem;
        text-align: center;
        color: #9a41cb;
    }

    .standardlist {
        width: 100%;
        display: flex;
        grid-gap: 1.2rem;
        gap: 1.2rem;
        justify-content: center;
    }


    .fovourtcards {
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 2rem;
        text-align: center;
        color: #9a41cb;
        width: 211px;
        background: white;
        padding: 1rem;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }


    .svgclass {
        height: 77px;
        margin: auto;
        display: flex;
        margin-bottom: 2rem;
    }

    .landingchat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100% - 165px);
        overflow-y: scroll;
        width: 100%;
        max-width: 100%;
        margin: auto;
        padding: 0;
        position: relative;
        padding-top: 3rem;

        &::-webkit-scrollbar {
            display: none !important;
        }

    }

    .chat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: calc(100% - 165px);
        overflow-y: scroll;
        width: 100%;
        max-width: 100%;
        margin: auto;
        padding: 0;
        position: relative;
        padding-bottom: 13px;
        padding-top: 15px;

        &::-webkit-scrollbar {
            display: none !important;
        }

    }

    p {
        margin-bottom: 0;
        font-size: 14px;
    }

    .bot,
    .bot-message {
        align-self: flex-start;
        font-weight: 500;
        padding: 10px;
        max-width: 100%;
        word-wrap: break-word;
        font-size: 16px;
        font-family: "Exo 2" !important;
        background: white;
        position: relative;
        padding-bottom: 2rem;
        border-radius: 15px;
    }

    .user-message {
        align-self: self-start;
        font-family: "Exo 2" !important;
        background-color: #fff;
        color: #000;
        padding: 10px;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
        border: 1px solid #e5e5e5;
    }

    .copytect {
        font-family: "Orbitron" !important;
        display: flex;
        align-items: center;
        font-weight: 600;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        color: #9a41cb;
        font-size: 11px;
        cursor: pointer;
        position: absolute;
        padding: 0 7px;
        border-radius: 5px;
        right: 0px;
        /* top: 4px; */
        width: 100%;
        justify-content: center;
        bottom: 8px;
    }

    .image-upload>input {
        display: none;
    }

    .cirlec {
        background: #000;
        min-width: 60px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3rem;

    }

}

.mainSectionluca {
    display: flex;
    align-items: start;
}

.dark-mode {
    background-color: #000;

    div {
        color: white !important;
    }

    svg {
        color: white !important;
    }

    .login {
        background-color: #FFF !important;
        color: #000 !important
    }
}

.lightdarkmode {
    background-color: #000000d6 !important;



    svg {
        color: #000 !important;
    }

    .heltext {
        color: #fff !important;
    }

    .mobilebuon {
        color: #fff !important;

        svg {
            color: #fff !important;
            fill: #fff !important;
        }
    }

    .fovourtcards {
        color: #000 !important;
    }

    .landingchat svg {
        color: #fff !important;
    }
}

.dark-mode-textaree {
    svg {
        color: #000 !important;
    }
}


.mainmodelseacrh {
    width: 500px;
    background: white;

    input {
        width: 100%;
        height: 30px;
        align-items: center;
        height: 36px;
        background: #fff;
        border-radius: 10px;
        padding: 6px 6px 9px 16px;
        font-family: "Exo 2" !important;
        font-size: 14px;
        border: 1px solid #9a41cb;

    }
}

.modeldatalist {
    height: 400px;
    overflow: scroll;

    .textlist {
        // display: flex;
        // align-items: center;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.converationsection {
    // height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.7rem;

    .textlistmodel {
        position: relative;
        padding-left: 40px;
        cursor: pointer;

    }

    svg {
        width: 2rem;
        height: 2rem;
        min-width: 2rem;


    }
}

.histroytext {
    font-size: 18px;
    font-weight: 700;
    font-family: "Orbitron" !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #9a41cb;
}

.modelseacrhtext {
    padding: '10px';
    background: '#f1f1f1';
    border-radius: '10px';

    font-size: 14px;
    font-weight: 400px;
    font-family: "Exo 2" !important;
    // display: flex;
    // align-items: center;
    // justify-content: start;
    color: #9a41cb;
}

.anseermodeltext {
    width: 450px;


    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 1rem;
    white-space: nowrap;
}

.modelseachTable {
    width: 450px;

    font-family: "Exo 2" !important;
    font-size: 16px;
    color: #9a41cb;
    font-weight: 700;
    // display: flex;
    // align-items: center;
    // justify-content: start;
    text-overflow: ellipsis;
    overflow: hidden;

    white-space: nowrap;
}

@media screen and (min-width:'300px') and (max-width:'900px') {
    .rightsidedaata .fileNamediv1 {
        width: 170px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .topNavbar {}

    .topNavbar .text {
        display: none;
    }

    .toplucanavbar .standardlist {
        display: none;
    }

    .toplucanavbar .flextshow {
        width: 100%;
    }

    .toplucanavbar .heltext {
        font-size: 23px;
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 33px;
        margin-bottom: 2rem;
        text-align: center;
        color: #9a41cb;
    }

    .toplucanavbar .chat {

        gap: 1rem;
    }
}