.leftsidechathistory {
    height: 100%;
    width: 300px;
    max-width: 300px;
    border-right: 1px solid #ddd;
    position: relative;
    z-index: 2;
    padding-top: 2rem;
    padding-bottom: 4rem;
    min-width: 300px;

}

.bavdesktoptsvg {
    margin-bottom: 1rem;
}

.histroypathsection {
    display: flex;
    align-items: start;
    width: 100%;
    padding: 0 5%;
    gap: 2rem;
    height: 100%;

    .textline {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        padding-right: 2rem;
        font-size: 16px;
        margin-bottom: 6px;
        line-height: 32px;
        cursor: pointer;
        transition: s;
        transition: all 0.5s ease-out;
        font-weight: 100;

        &:hover {
            transition: all 0.5s ease-out;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .listoghistory {
        height: calc(100% - 3rem);
        overflow: scroll;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .histroytext {
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 1.5rem;
    }
}

.Appasdas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    background-color: #f2f2f233;

    align-items: center;
    background-repeat: no-repeat;
    background-size: 1200px;
    margin: auto;
    background-position: top;

    p {
        font-size: 14px;
    }

    li {
        font-size: 14px;
        margin-top: 10px;
    }

    strong {
        font-size: 16px;
    }

    dl,
    ol,
    ul {
        padding-left: 15px;
    }

    h4 {
        font-size: 19px !important;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-weight: 400 !important;
    }

    h3 {
        font-size: 22px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }
    .standardlist {
        width: 100%;
        display: flex;
        gap: 1.2rem;
        justify-content: center;
    }

    .fovourtcards {
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 2rem;
        text-align: center;
        color: #9A41CB;
        width: 211px;
        background: #ddddddb8;
        padding: 1rem;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .fileNamediv {
        font-size: 16px;
        font-weight: 700;
        line-height: 2.25rem;
        text-decoration: underline;
        text-align: center;
        color: #9A41CB;
        top: -38px;
        left: 23px;
        position: absolute;
        white-space: nowrap;
    }

    .fileDivsection {
        width: 100%;
        position: relative;
    }

    .image-upload>input {
        display: none;
    }

    .image-upload {
        position: absolute;
        top: 6px;
        left: -18px;
    }

    .image-upload svg {
        width: 80px;
        cursor: pointer;

    }

    .tablesinde {
        width: 100%;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

 

    .chatapp {
        height: 100%;
        width: calc(100% - 300px);
        padding-bottom: 9.5rem;
        // padding-top: 6rem;
        position: relative;
        z-index: 2;
    }

    .bottomsection {
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
    }

    .whittitnt {
        background: #ffffffb5;
        position: absolute;
        height: 100vh;
        top: 0;
        z-index: 1;
        width: 100%;
    }

    .howcanihelpwith {
        height: 100vh;
        padding-top: 77px;
        width: 100%;
        position: relative;
        padding-bottom: 0px;
        display: flex;

        align-items: center;
        background-repeat: no-repeat;
        background-size: 1200px;
        font-family: "Orbitron" !important;
        margin: auto;
        background-position: top;
    }

    .heltext {
        font-size: 35px;
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 2.25rem;
        margin-bottom: 2rem;
        text-align: center;
        color: #9A41CB;

    }

    .svgclass {

        margin: auto;
        margin-bottom: 4rem;
    }



    .chat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        overflow-y: scroll;
        padding: 10px;
        width: 100%;
        max-width: 1300px;
        margin: auto;
        padding-top: 10px;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .user-message {
        align-self: self-start;
        font-family: "Exo 2" !important;
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
        border: 1px solid #E5E5E5;
    }

    .flextshow {
        display: flex;
        align-items: self-start;
        gap: 1rem;
        width: 48%;
    }

    .cirlec {
        background: black;
        min-width: 60px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3rem;
    }

    .bot {
        align-self: flex-start;
        font-weight: 500;
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
        font-size: 16px;

        border: 1px solid #E5E5E5;
        font-family: "Exo 2" !important
    }

    .bot-message {
        background-color: #000;
        font-family: "Exo 2" !important;
        color: white;
        align-self: flex-start;
        font-weight: 500;
        padding: 10px;

        font-size: 16px;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
    }

    .inner {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 2;
    }

    .input-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    form {
        width: 100%;
        display: flex;
        gap: 1rem;
    }

    input[type="text"] {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid green;
        outline: none;
        border: 1px solid #ddd;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        height: 44px;
        background: white;
        border-radius: 10px;
        padding-left: 2.5rem;
        font-family: "Exo 2" !important;
    }

    .textbottom {

        font-size: 14px;
        font-family: "Orbitron" !important;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        color: #718096;

        span {
            color: black;
            text-decoration: underline;
            font-weight: 700;
        }
    }

    button {

        font-size: 16px;
        font-weight: 600;
        font-family: "Orbitron" !important;
        line-height: 16px;
        min-width: 180px;
        height: 44px;
        border-radius: 10px;
        border: none;
        outline: none;
        background: #9A41CB;

        color: #fff;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }


}


@media screen and (min-width:'300px') and (max-width:'900px') {
    .Appasdas .bot {
        font-size: 13px;
    }

    .histroypathsection {
        display: flex;
        align-items: start;
        width: 100%;
        padding: 0 0%;
        grid-gap: 2rem;
        gap: 2rem;
        height: 100%;
    }

    .bavdesktoptsvg {
        margin-bottom: 1rem;
        display: none;
    }

    .leftsidechathistory {

        display: none;
    }

    .Appasdas .standardlist {

        display: none;
    }

    .Appasdas .user-message {
        font-size: 13px;
    }

    .Appasdas .textbottom {
        color: #718096;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        display: none;
    }

    .Appasdas .mobilebuon {
        width: 13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 15px;
        margin: auto auto 1rem !important;
        font-size: 14px !important;
    }

    .Appasdas input[type=text] {
        font-size: 13px;
        height: 40px;
    }

    .Appasdas .flextshow {
        display: flex;
        align-items: self-start;
        gap: 1rem;
        width: 80%;
    }

    .Appasdas .bottomsection {
        padding: 0 5%;
    }

    .Appasdas .svgclass {
        margin: auto;
        margin-bottom: 2rem;
        height: 106px;
        width: 284px;
    }

    .Appasdas .heltext {
        font-size: 1.375rem;

    }

    .Appasdas .howcanihelpwith {
        height: 100vh;
        padding-top: 89px;
        width: 100%;
        position: relative;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .Appasdas form {
        display: flex;
        gap: 10px;
    }

    .Appasdas {
        padding: 0 5%;
    }

    .Appasdas .chatapp {
        height: 100vh;
        padding-bottom: 5rem;
        padding-top: 6rem;
        width: 100%;
    }

    .Appasdas button {
        background: #9A41CB;
        border: none;
        border-radius: 45px;
        color: #000;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        height: 40px;
        line-height: 16px;
        min-width: 76px;
        outline: none;
    }

    .Appasdas .flextshow {

        width: 100%;
        margin-top: 13px;
    }
}