.propmtmessage {
    padding: 0 5%;
    height: 100vh;
    padding-top: 2rem;

    .svgclass {
        height: 100px;
        margin: auto;
        display: flex;
        margin-bottom: 2rem;
    }

    textarea {
        width: 100%;
        height: calc(100vh - 253px);
        border-radius: 10px;
        padding: 1rem;
        border: 1px solid #ddd;
    }

    label {
        font-size: 21px;
        font-family: "Orbitron" !important;
        font-weight: 700;
        line-height: 2.25rem;
        margin-bottom: 2rem;
        text-align: center;
        color: #fff;
        background: #9a41cb;
        cursor: pointer;
        width: 13rem;
        margin: auto;
        display: flex;
        margin-top: 2rem;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }
}

.loderlogin {
    background: #9a41cb;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transition: all 0.3s linear;
        animation: blink 1s infinite;
    }
}

@keyframes blink {
    0% {
        opacity: 0
    }

    49% {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}