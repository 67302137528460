.registration {
    display: flex;
    width: 100%;
    height: auto;
    background: #fff;

    padding: 2rem 0;

    &>.leftContainer {
        width: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &>.topLogo {
            display: flex;
            align-items: center;
            padding: 1.5rem;

            &>img {
                height: 2rem;
            }
        }

        &>.loginContainer {
            height: auto;
            width: 600px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            margin-top: 0;
            padding: 0rem 0;
            justify-content: center;
            background-color: white;
            padding: 1rem;
            background: #fff;
            padding: 2rem;
            text-align: center;
            border-radius: 15px;


            &>.inputsContainer {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                height: 100%;
                width: 100%;

                &>.header {
                    &>.title {
                        font-size: 1.5rem;
                        font-weight: 550;
                        margin-bottom: 10px;
                        font-size: 1.5rem;
                        font-weight: 550;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &>.desc {
                        line-height: 1.5;
                    }
                }

                &>.inputs {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    width: 100%;

                    &>.flexContainer {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 0.7rem;
                    }

                    &>.phoneNumberContainer {
                        display: flex;
                        gap: 0.7rem;

                        &> :nth-child(1) {
                            width: 100px;
                        }

                        &> :nth-child(2) {
                            width: calc(100% - 100px);
                        }
                    }

                    .usernameAvailable {
                        position: relative;

                        &>.availablitypoint {
                            position: absolute;
                            top: 50%;
                            right: 0.5rem;
                            transform: translate(-50%, -50%);
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #f5515f;
                        }
                    }
                }

                .loginBtn {
                    padding: 1rem;
                    width: 350px;

                    color: white;
                    cursor: pointer;
                    text-align: center;
                    border-radius: 6px;
                    margin-top: auto;
                    margin-left: auto;
                    transition: all linear 0.3s;
                    background: #9a41cb;
                    margin: auto;
                    margin-top: 1rem;

                    &:hover {
                        scale: 1.03;
                    }
                }

                .notAllowed {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }

    &>.rightContainer {
        width: 35%;
        //   background: var(--main-color);
    }
}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .registration {
        display: flex;
        width: 100%;
        height: auto;
        background: #fff;

        padding: 5rem 0;

        &>.leftContainer {
            width: 100%;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &>.topLogo {
                display: flex;
                align-items: center;
                padding: 1.5rem;

                &>img {
                    height: 2rem;
                }
            }

            &>.loginContainer {
                height: auto;
                width: 100%;
                margin: 0 auto;
                display: flex;
                align-items: center;
                margin-top: 0;
                padding: 0rem 0;
                justify-content: center;
                background-color: white;
                padding: 1rem;
                background: #fff;
                padding: 0 5%;
                text-align: center;
                border-radius: 15px;


                &>.inputsContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    height: 100%;
                    width: 100%;

                    &>.header {
                        &>.title {
                            font-size: 1.5rem;
                            font-weight: 550;
                            margin-bottom: 10px;
                            font-size: 1.5rem;
                            font-weight: 550;
                            margin-bottom: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &>.desc {
                            line-height: 1.5;
                        }
                    }

                    &>.inputs {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        width: 100%;

                        &>.flexContainer {
                            width: 100%;
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 0.7rem;
                        }

                        &>.phoneNumberContainer {
                            display: flex;
                            gap: 0.7rem;

                            &> :nth-child(1) {
                                width: 100px;
                            }

                            &> :nth-child(2) {
                                width: calc(100% - 100px);
                            }
                        }

                        .usernameAvailable {
                            position: relative;

                            &>.availablitypoint {
                                position: absolute;
                                top: 50%;
                                right: 0.5rem;
                                transform: translate(-50%, -50%);
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                background: #f5515f;
                            }
                        }
                    }

                    .loginBtn {
                        padding: 1rem;
                        width: 100%;



                        &:hover {
                            scale: 1.03;
                        }
                    }

                    .notAllowed {}
                }
            }
        }

        &>.rightContainer {
            width: 35%;
            //   background: var(--main-color);
        }
    }



}