/* Sidebar.css */
.sidebar {
    width: 250px;
    background: #2c2c2c;
    color: white;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar p {
    margin: 0;
    padding: 10px 0;
    cursor: pointer;
   // transition: background 0.3s;
  }
  
  .sidebar p:hover {
    background: #444;
  }
  