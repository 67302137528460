.customTextInput {
  &>.title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &>.inputContainer {
    position: relative;

    &>input {
      background: #fff;
      border: .5px solid #e5e5e5;
      border-radius: 6px;
      outline: none;
      padding: 10px;
      width: 100%;
      font-family: "Exo 2" !important;

      // &:focus {
      //   border-color: var(--main-color);
      // }

      &::placeholder {
        color: #9a41cb;
        font-weight: 550;
      }
    }

    &>.showPasword {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translate(-50%, -50%);
      height: max-content;

      &>svg {
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
  }
}

.eachCustomDropdown {
  position: relative;
  // min-width: 220px;
  height: 3rem;
  cursor: pointer;
  white-space: nowrap;
  transition: all linear 0.3s;

  //margin-bottom:2rem;
  &::placeholder {
    display: none;
  }

  &>.topTitle {
    font-size: 0.9rem;
    margin-top: 1rem;
  }

  &>.inputContainer {
    padding: 10px;
    border-radius: 5px;
    border: 0.5px solid #e5e5e5;
    font-size: 0.8rem;
    background: #fff;
    width: 100%;
    // max-width: 500px;
    position: absolute;
    top: 0rem;
    color: #9a41cb;
    font-size: 16px;
    z-index: 2;
    font-weight: 600;
    max-height: 400px;
     overflow-y: scroll;
    transition: all ease-in 0.2s;
&::-webkit-scrollbar{
  display: none;
}
    &>span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    &>ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;
      padding: 0;

      &>li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        &:hover {
          transform: scale(1.03);
          color: #0d264c;
        }

        &>img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      &>li:has(input) {
        padding: 0;

        &>input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em;
      /* Adjust the width as needed */
      background: transparent;
      /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.eachCustomImageUpload {
  position: relative;
  // min-width: 200px;
  cursor: pointer;
  transition: all linear 0.3s;

  &>.topTitle {
    margin-top: 1rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  &>.imageInput {
    width: max-content;
    background: none;
    border: none;

    img {
      // width: 100%;
      height: 8rem;
      width: 8rem;
      border-radius: 50%;
      border: 0.5px solid #e5e5e5;
    }

    &>.imageLabelcustom {
      white-space: nowrap;
      text-align: center;
      margin-top: 1rem;
      color: #9a41cb;

      font-size: 0.9rem;
      font-weight: 450;
      cursor: pointer;
      width: 153px;
      height: 43px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4rem;
      border: 1px solid #ebebeb;
    }

    &>.imageLabel {
      white-space: nowrap;
      text-align: center;
      margin-top: 1rem;
      color: #9a41cb !important;
      font-size: 0.9rem;
      font-weight: 600;
      cursor: pointer;
    }

    &>.emptyProfilecustom {
      width: 6rem;
      height: 6rem;
      border-radius: 85px;
      background: #f5f5f5;
      border: 0.5px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      &>img {
        width: 3rem;
        height: 3rem;
        border: none;
        transition: all linear 0.3s;
      }

      &:hover {
        border: 0.5px solid var(--main-color);

        &>img {
          height: 4rem;
          width: 4rem;
        }
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    &>.emptyProfile {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background: #f5f5f5;
      border: 0.5px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      &>img {
        width: 3rem;
        height: 3rem;
        border: none;
        transition: all linear 0.3s;
      }

      &:hover {
        border: 0.5px solid var(--main-color);

        &>img {
          height: 4rem;
          width: 4rem;
        }
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    &>.emptyProfileLength {
      height: 6rem;
      width: 100%;
      border-radius: 5px;
      background: #f5f5f5;
      border: 0.5px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      &>img {
        width: 3rem;
        height: 3rem;
        border: none;
        transition: all linear 0.3s;
      }

      &:hover {
        border: 0.5px solid var(--main-color);

        &>img {
          height: 4rem;
          width: 4rem;
        }
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }
}