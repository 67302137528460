/* UserSignupReport.css */
.report-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .report-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .user-count-section {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Layout adjustment */
  .report-content {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  /* Chart Section */
  .chart-card {
    flex: 2;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* User List Section */
  .user-list {
    flex: 1;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .user-list h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #4CAF50;
  }
  
  .user-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .user-list li {
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .user-list li:last-child {
    border-bottom: none;
  }
  
  /* Table Styling */
  .signup-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .signup-table th, .signup-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .signup-table th {
    background: #4CAF50;
    color: white;
  }
  
  .signup-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .loading {
    font-size: 18px;
    color: gray;
    text-align: center;
    margin-top: 20px;
  }
  