@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800&display=swap");

* {
    box-sizing: border-box;
    // font-family: "Orbitron" !important;
    font-family: "Exo 2" !important;

    font-style: normal;
    outline: none;
    margin: 0;
}